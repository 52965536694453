@import '../../constants/variables.scss';

.navbarContainer {
  @include flex-container(row, center, space-between, noWrap);
  padding: 20px 0;
  position: sticky;
  top: 0;
  background-color: var(--backPrimary);
  z-index: 5;

  &_search {
    margin-right: 20px;
    min-width: 260px;
  }

  &_row {
    @include flex-container(row, center, flex-start);
  }
}

.popper {
  background-color: var(--primaryWhite);
  padding: 24px 20px;
  margin-top: 5px;
  border-radius: 9px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  &_row {
    @include flex-container(row, center, flex-start, noWrap);
    margin-bottom: 30px;

    &_title {
      margin-left: 20px;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;
      color: var(--textPrimary);
    }

    &_imageContainer {
      min-width: 48px;
      @include flex-container(row, center, flex-start);

      &_image {
        cursor: pointer;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
