@import '../../constants/variables.scss';

.pagination {
  @include flex-container(row, center, center, nowrap);
  margin-top: 20px;

  &_button {
    border-radius: 10px;
    font-weight: 700;
    font-size: 13px;
    color: white;
    background-color: var(--primaryBright);
    padding: 8px 12px;
    line-height: 20px;
    margin: 0 12px;

    &:disabled {
      background-color: var(--grey);
      cursor: default;
    }
  }

  &_pageButton {
    margin: 0 8px;
    height: 36px;
    width: 36px;
    display: block;
    border-radius: 9px;

    &:disabled {
      cursor: default;
    }
  }

  &_dotsButton {
    cursor: default;
    &:hover {
      background-color: white;
    }
  }
}
