@import '../../../../../constants/variables.scss';

.results {
  position: absolute;
  margin-top: 10px;
  background-color: var(--primaryWhite);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 12px;
  width: 600px;
  left: -340px;

  &_tabs {
    @include flex-container(row, center, space-between);
    margin-bottom: 12px;

    &_button {
      width: 24%;
      cursor: pointer;
      @include flex-container(row, center, center);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &_skeleton {
      cursor: default;
      display: block;
    }
  }

  &_cards {
    &_item {
      padding: 20px 0;
      border-bottom: 1px solid var(--greyDivider);
      width: 100%;

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    }
  }
}
