@import '../../../constants/variables.scss';

.emptyBlock {
  @include flex-container(column, center, center);
  padding: 100px 0;
  width: 100%;

  &_title {
    color: var(--primaryLow);
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &_description {
    color: var(--primaryLow);
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }

  &_button {
    margin-top: 40px;
    background-color: var(--primaryBright);
    color: var(--primaryWhite);
    font-size: 15px;
    font-weight: 700;
    padding: 10px 16px;
    border-radius: 14px;

    &:hover {
      background-color: var(--primary);
    }
  }
}
