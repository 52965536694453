@import '../../constants/variables.scss';

.avatar {
  width: 48px;
  height: 48px;
  cursor: pointer;
  &_image {
    border-radius: 100%;
    height: 100%;
    width: 100%;
  }

  &_empty {
    background-color: var(--primaryLight);
    height: 100%;
    border-radius: 100%;
    @include flex-container(row, center, center);

    &_name {
      font-size: 15px;
      color: var(--primaryLow);
      font-weight: 700;
    }
  }
}
