@import '../../constants/variables.scss';

.loader {
  @include flex-container(row, center, center);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(var(--primaryWhiteRGB), 0.5);
  z-index: 1000;
}
