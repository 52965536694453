.light {
  --accentColor: #f6c344;

  --primaryDark: #00004e;
  --primary: #000062;
  --primaryBright: #000092;
  --primaryLow: #545f7c;
  --primaryLight: #d6d6ec;
  --primaryWhite: white;
  --primaryWhiteRGB: 255, 255, 255;
  --primateBlackRGB: 0, 0, 0;

  --loginBright: #000092;
  --buttonBright: #000092;

  --grey: #cfd4d9;
  --greyDivider: #ebebf2;
  --greyLight: #f7f6f6;

  --systemRed: #d3473e;
  --systemGreen: #86b830;
  --systemPurple: #9747ff;

  --textPrimary: #0d0d34;
  --backPrimary: #f7f6f6;
}

.dark {
  --accentColor: #f6c344;

  --primaryDark: #a8a8dc;
  --primary: #9494de;
  --primaryBright: #9494de;
  --primaryLow: #aeb0c8;
  --primaryLight: #212552;
  --primaryWhite: #3e3f4f;
  --primaryWhiteRGB: 62, 63, 79;
  --primateBlackRGB: 255, 255, 255;

  --loginBright: #cecedf;
  --buttonBright: #9494de;

  --grey: #636680;
  --greyDivider: #636680;
  --greyLight: #31323d;

  --systemRed: #d3473e;
  --systemGreen: #86b830;
  --systemPurple: #9747ff;

  --textPrimary: #dfdfec;
  --backPrimary: #31323d;
}
