@import '../../../constants/variables.scss';

.commonButton {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: var(--primaryLow);
  background-color: var(--primaryWhite);
  padding: 8px 12px;
  border-radius: 8px;
  @include flex-container(row, center, flex-start, nowrap);

  &:hover {
    background-color: var(--greyLight);
  }
}

.activeButton {
  background-color: var(--primaryLight);
  color: var(--textPrimary);

  &:hover {
    background-color: var(--primaryLight);
  }
}

.icon {
  margin-right: 12px;

  path {
    fill: var(--primaryBright);
  }
}

.activeIcon {
  path {
    fill: var(--primaryBright);
  }
}
