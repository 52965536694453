@import '../../../constants/variables.scss';

.wrapper {
  @include flex-container(row, flex-start, flex-start, nowrap);

  &_main {
    margin: 0 32px 25px 42px;
    width: calc(100% - 314px);
  }
}
