@import "../../constants/variables.scss";

.sidebarContainer {
  background-color: var(--primaryWhite);
  min-width: 240px;
  height: 100vh;
  padding: 42px 20px;
  @include flex-container(column, center, flex-start);
  position: sticky;
  top: 0;
  &_logo {
    width: 160px;
    height: 17px;
  }

  &_navigation {
    margin-top: 90px;
    width: 100%;

    &_item {
      width: 100%;
      border-radius: 14px;
      padding: 0 20px;
      margin-bottom: 20px;
      line-height: 18px;
    }

    &_accordion {
      padding-right: 0;
    }

    &_link {
      text-align: left;
      height: 48px;
      font-weight: 600;

      &_active {
        color: var(--primaryBright);
        font-weight: 600;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.subLink {
  padding: 8px 0 8px 30px;
  position: relative;
  margin-top: 10px;

  &_name {
    font-weight: 600;
    color: var(--primaryLow);
    cursor: pointer;
  }

  &_active {
    &::before {
      content: "";
      height: 100%;
      width: 8px;
      position: absolute;
      left: -40px;
      top: 0;
      border-radius: 0 10px 10px 0;
      background-color: var(--primaryLight);
    }
  }
}
