@import '../../../constants/variables.scss';

.breadcrumbs {
  background-color: var(--primaryWhite);
  border-radius: 10px;
  height: 30px;
  @include flex-container(row, center, center);
  padding: 0 11px 0 20px;

  &_home {
    cursor: pointer;
    width: 14px;
    height: 12px;

    path {
      fill: var(--primaryLow);
    }
  }

  &_link {
    @include flex-container(row, center, center);
    margin-left: 9px;
    &_description {
      color: var(--primaryLow);
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      margin-left: 9px;
      cursor: pointer;

      &_active {
        color: var(--textPrimary);
        cursor: default;
      }
    }

    &_arrow {
      width: 6px;
      height: 8px;
      path {
        fill: var(--primaryLow);
      }
    }
  }
}
