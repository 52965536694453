@import './constants/variables.scss';

* {
  box-sizing: border-box;
  outline: none;
}

@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  src: url('./assets/fonts/Manrope-Light.ttf');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  src: url('./assets/fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  src: url('./assets/fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  src: url('./assets/fonts/Manrope-SemiBold.ttf');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  src: url('./assets/fonts/Manrope-Bold.ttf');
}

body {
  margin: 0;
  list-style-type: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backPrimary);
  color: var(--textPrimary);
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Manrope';
  border-radius: 14px;
  transition: $baseTransition;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 24px;
  font-size: 15px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 8px 0 0 8px !important;
  background-color: var(--primaryWhite) !important;
}

.react-tel-input .selected-flag {
  &:hover,
  &:active,
  &:focus {
    background-color: var(--primaryWhite);
    border-radius: 8px 0 0 8px;
  }
}

.react-tel-input :disabled {
  cursor: default !important;
}

.react-datepicker__close-icon {
  &::after {
    background-color: transparent !important;
    color: var(--grey) !important;
    font-size: 28px !important;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--textPrimary) !important;
  margin-bottom: 10px;
}

.react-datepicker__header {
  background-color: var(--primaryWhite) !important;
  border-bottom: 1px solid var(--greyDivider) !important;
}

.react-datepicker__header__dropdown {
  @include flex-container(row, center, space-between);
  padding: 0 10px;
}

.react-datepicker__year-select,
.react-datepicker__month-select {
  background-color: var(--primaryWhite);
  border: 1px solid var(--greyDivider);
  padding: 4px 12px;
  border-radius: 6px;
  color: var(--textPrimary);
  cursor: pointer;
  min-width: 100px;
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primaryBright) !important;
  color: white !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: var(--greyDivider) !important;
}

.react-datepicker__day-name,
.react-datepicker__time-name {
  color: var(--textPrimary) !important;
}

.slick-track {
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-thumb {
  background: var(--greyDivider);
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--greyLight);
}
