@import "../../../constants/variables.scss";

.commonAccordion {
    &_head {
        @include flex-container(row, center, space-between);
        cursor: pointer;

        &_info {
            @include flex-container(row, center, flex-start);

            &_icon {
                margin-right: 12px;
                path {
                    fill: var(--primaryBright);
                }
            }

            &_name {
                font-weight: 600;
                color: var(--primaryLow);
            }
        }

        &_arrow {
            transform: rotate(90deg);
            transition: $baseTransition;
            path {
                fill: var(--grey);
            }
        }

        &_activeArrow {
            transform: rotate(-90deg);
        }
    }
}
