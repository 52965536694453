@import '../../../constants/variables.scss';

.search {
  display: block;
  &_container {
    position: relative;

    &_input {
      padding: 8px 12px;
      border-radius: 8px;
      height: 44px;
      border: 1px solid var(--greyDivider);
      font-size: 15px;
      width: 100%;
      color: var(--textPrimary);
      background-color: var(--primaryWhite);
      &::placeholder {
        color: var(--primaryLow);
        font-family: 'Manrope';
      }
    }

    &_errorInput {
      border: 1px solid var(--systemRed);
      color: var(--systemRed);
    }

    &_cross {
      position: absolute;
      right: 12px;
      margin: auto 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  &_error {
    font-size: 13px;
    color: var(--systemRed);
    top: 4px;
    position: relative;
  }
}
