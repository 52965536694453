@import '../../../../../constants/variables.scss';

.searchCard {
  width: 100%;
  cursor: pointer;

  &_info {
    @include flex-container(row, center, flex-start);

    &_logo {
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin-right: 20px;
    }

    &_description {
      margin-top: 8px;
      white-space: pre;
    }
  }
}
